import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Styles from './FormElement.styles'

const Container = styled.div`
  ${Styles.container}
`
const RadioGroup = styled.div`
  ${Styles.radioGroup}
`
const Caption = styled.div`
  ${Styles.caption}
`
const Input = styled.input.attrs({
  type: 'input'
})`
  ${Styles.textbox}
`
const Select = styled.select`
  ${Styles.select}
`
const Option = styled.option`
  ${Styles.option}
`
const RadioOption = styled.input.attrs({
  type: 'radio'
})`
  ${Styles.radio}
`
const RadioLabel = styled.label`
  ${Styles.radioLabel}
`
const Label = styled.label`
  ${Styles.label}
`
const JaliscoCaption = styled.div`
  ${Styles.jaliscoCaption}
`
const JaliscoRadioGroup = styled.div`
  ${Styles.jaliscoRadioGroup}
`
const JaliscoRadioLabel = styled.label`
  ${Styles.jaliscoRadioLabel}
`

const TextInput = ({ caption, onChange, value, disabled, optional }) => {
  return (
    <Container>
      <Caption>
        {caption}
        {optional && (
          <span style={{ fontWeight: 350, marginLeft: '0.7em' }}>
            (Opcional)
          </span>
        )}
      </Caption>
      <Input disabled={disabled} value={value} onChange={onChange} />
    </Container>
  )
}

const SelectInput = ({
  caption,
  onChange,
  options,
  selected,
  disabled,
  optional
}) => {
  return (
    <Container>
      <Caption>
        {caption}
        {optional && (
          <span style={{ fontWeight: 350, marginLeft: '0.7em' }}>
            (Opcional)
          </span>
        )}
      </Caption>
      <Select disabled={disabled} value={selected} onChange={onChange}>
        {options.map((option, idx) => {
          return (
            <Option key={`select-${idx}`} value={option.value}>
              {option.caption}
            </Option>
          )
        })}
      </Select>
    </Container>
  )
}

const RadioInput = ({ onChange, options, selected, groupName }) => {
  return (
    <RadioGroup>
      {options.map((option, idx) => {
        return (
          <RadioLabel
            key={`radioInput-groupName-${idx}`}
            selected={selected === parseInt(option.value, 10)}
          >
            <RadioOption
              key={option.value}
              onChange={onChange}
              value={option.value}
              name={groupName}
              selected={selected === parseInt(option.value)}
            />
            {option.caption}
          </RadioLabel>
        )
      })}
    </RadioGroup>
  )
}

const JaliscoRadioInput = ({
  onChange,
  options,
  selected,
  groupName,
  caption
}) => {
  return (
    <Container style={{ marginRight: '10em' }}>
      <JaliscoCaption>{caption}</JaliscoCaption>
      <JaliscoRadioGroup>
        {options.map((option, idx) => {
          return (
            <JaliscoRadioLabel
              key={`radioInput-groupName-${idx}`}
              selected={selected === parseInt(option.value, 10)}
            >
              <RadioOption
                key={option.value}
                onChange={onChange}
                value={option.value}
                name={groupName}
                selected={selected === parseInt(option.value)}
              />
              {option.caption}
            </JaliscoRadioLabel>
          )
        })}
      </JaliscoRadioGroup>
    </Container>
  )
}

const Icon = styled.svg`
  fill: none;
  stroke: ${({ checked }) => (checked ? 'white' : '#BAC0C3')};
  stroke-width: 4px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  background: white;
  border: ${({ checked }) => (checked ? '#4597EA' : '#BAC0C3')} 3px solid;
  border-radius: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #eaedef;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  background-color: ${({ checked }) => (checked ? '#4597EA' : 'white')};
`

const JaliscoStyledCheckbox = styled.div`
  background: white;
  border: ${({ checked }) => (checked ? '#4597EA' : '#BAC0C3')} 3px solid;
  border-radius: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 2.3em;
  margin-left: 1em;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #eaedef;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  background-color: ${({ checked }) => (checked ? '#4597EA' : 'white')};
`

const CheckboxInput = ({ label, image, onChange, checked, disabled }) => (
  <Container>
    <Label>
      <HiddenCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <StyledCheckbox disabled={disabled} checked={checked}>
        <Icon checked={checked} viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </StyledCheckbox>
      <span style={{ margin: '5px' }}>{label}</span>
      <img alt='icono accesibilidad' src={image} />
    </Label>
  </Container>
)

const JaliscoCheckboxInput = ({
  label,
  image,
  onChange,
  checked,
  disabled
}) => (
  <Container>
    <JaliscoCaption>{label}</JaliscoCaption>
    <Label>
      <HiddenCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <JaliscoStyledCheckbox disabled={disabled} checked={checked}>
        <Icon checked={checked} viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </JaliscoStyledCheckbox>
      {image && <img alt='icono accesibilidad' src={image} />}
    </Label>
  </Container>
)

TextInput.propTypes = {
  caption: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool
}

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  caption: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.number,
  disabled: PropTypes.bool
}

RadioInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.number,
  groupName: PropTypes.string.isRequired
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  image: PropTypes.string,
  disabled: PropTypes.bool
}

const FormElements = {
  TextInput,
  SelectInput,
  CheckboxInput,
  RadioInput,
  JaliscoRadioInput,
  JaliscoCheckboxInput
}
export default FormElements
export {
  TextInput,
  SelectInput,
  CheckboxInput,
  RadioInput,
  JaliscoRadioInput,
  JaliscoCheckboxInput
}
