import React from 'react';
import styled from 'styled-components';
import Styles from './Footer.styles';
import AvisoLink from '../../components/AvisoLink';

import JaliscoDefiendeClaro from '../../icons/logo_jalisco_se_defiende_claro.png';
import LogoGobiernoEstado from '../../icons/logo_gobierno_del_estado.png'

const Container = styled.div`${Styles.footer}`;
const IconJalisco = styled.img`${Styles.footerIcon}`;
const IconGobierno = styled.img`${Styles.footerIcon}`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const Footer = ({color}) => {
    return (
        <Container color={color}>
            <IconJalisco src={JaliscoDefiendeClaro} alt="Logo Jalisco Se Defiende" />
            <AvisoLink />
            <IconGobierno src={LogoGobiernoEstado} alt="Logo Gobierno del estado de Jalisco" />
        </Container>
    );
};

Footer.propTypes = {
};

export default Footer;
