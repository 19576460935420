import { css } from 'styled-components';

const styles = {
    container: () => css`
        width: 100%;
        display: flex;
        flex-direction: row;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: #BAC0C3;
        padding: 1em;
        opacity: 1;
    `,
    subcontainer: () => css`
        width: 80%;
        display: flex;
        flex-direction: column;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: #BAC0C3;
        opacity: 1;
    `,
    icon: () => css`
        width: 2em;
        height: 2em;
        margin-right: 2em;
    `,
    form: () => css`
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: row;
        > * {
            width: 20%;
        }
        margin-bottom: 3em;
    `,
    title: () => css`
        width: 100%;
        text-align: left;
        font-size: 2em;
        color: #00326C;
        margin: 0;
    `,
    text: () => css`
        margin: 0;
        text-align: left;
        margin-top: 2.5em;
        margin-bottom: 2.5em;
        font-weight: normal;
        font-family: 'Nutmeg Book';
        width: 60%;
        color: #878795;
    `,

    caption: () => css`
        display: flex;
        justify-content: center;
        height: 1.25em;
    `,
    textbox: () => css`
        border-radius: 4px;
        color: #A6A6AF;
        padding-left: 1.5em;
        :disabled{
            background-color: #EAEDEF;
        }
    `,
    select: () => css`
    `,
    option: () => css`
    `,
};

export default styles;