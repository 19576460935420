import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Styles from './CurpForm.styles'
import TextCurp from '../../components/TextCurp'
import PrimaryButton from '../../components/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  ${Styles.container}
`
const Title = styled.h3`
  ${Styles.header}
`
const BottomText = styled.span`
  ${Styles.bottom}
`
const ErrorText = styled.span`
  ${Styles.errortext}
`
const Link = styled.a`
  ${Styles.link}
`
const Form = styled.div`
  ${Styles.form}
`

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const CurpForm = () => {
  const [curp, setCurp] = useState('')
  const [cp, setCP] = useState('')
  const [loading, setLoading] = useState(false)
  const [serviceAvailable, setServiceAvailable] = useState(true)
  const [loadingService, setLoadingService] = useState(true)
  const [error, setError] = useState('')
  useEffect(() => {
    setLoadingService(false)
  }, [])

  const loadCurp = () => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/registro/getInfoByCurp?curp=${curp}`
    )
      .then(res => res.json())
      .then(data => {
        setLoading(false)
        if (data.cita) {
          window.localStorage.setItem('cita', JSON.stringify(data.cita))
          window.localStorage.setItem('userRegister', JSON.stringify({ curp }))
          window.location.href = '/appointment'
          return
        }
        window.localStorage.setItem('user', JSON.stringify(data.data))
        window.localStorage.setItem('curp', JSON.stringify(curp))
        window.location.href = '/register'
      })
      .catch(err => {
        alert(
          'Ocurrió un error consultando la CURP. Por favor intenta de nuevo más tarde.'
        )
        setLoading(false)
      })

    // window.grecaptcha.ready(() => {
    //     window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
    //         setLoading(true);
    //         const appointmentPromise = fetch(`${process.env.REACT_APP_API_URL}/registro/getInfoByCurp?curp=${curp}&token=${token}`).then((appointment) => (appointment.json()));
    //         Promise.all([appointmentPromise])
    //             .then(([appointment]) => {
    //                 if (!appointment.error) {
    //                     setLoading(false);
    //                     window.localStorage.setItem('curp', JSON.stringify(curp));
    //                     window.localStorage.setItem('appointment', JSON.stringify(appointment.cita));
    //                     window.localStorage.setItem('validation', JSON.stringify(appointment.validacion));
    //                     if (appointment.validacion) {
    //                         appointment.data.udg = appointment.udg;
    //                         appointment.data.primer_apellido = appointment.data.primerApellido;
    //                         appointment.data.segundo_apellido = appointment.data.segundoApellido;
    //                         window.localStorage.setItem('appointment', JSON.stringify(appointment.data));
    //                     }
    //                     window.localStorage.setItem('error', '');
    //                 }
    //                 else {
    //                     setLoading(false);
    //                     window.localStorage.setItem('curp', JSON.stringify(curp));
    //                     window.localStorage.setItem('appointment', null);
    //                     window.localStorage.setItem('error', appointment.error_descripcion);
    //                 }
    //                 window.location.href = `${process.env.REACT_APP_URL}` + "/appointment";
    //             })
    //             .catch((err) => {
    //                 const alertMsg = "Ocurrió un error consultando la CURP. Por favor intenta de nuevo más tarde";
    //                 setError(alertMsg);
    //                 setLoading(false);
    //                 return;
    //             })

    //     });
    // });
  }

  let FormComponent = <></>
  if (!loadingService) {
    if (serviceAvailable && error) {
      FormComponent = (
        <Form>
          <ErrorText color={'#676D72'}>{error}</ErrorText>
        </Form>
      )
    }
    if (serviceAvailable && !error) {
      if (loading)
        FormComponent = (
          <Form>
            <TextCurp value={curp} onChange={setCurp} />
            <PrimaryButton
              className='wait'
              disabled={curp.length !== 18 || loading}
            >
              <FontAwesomeIcon icon={faCircleNotch} spin color='white' />
              &nbsp;Buscando ...
            </PrimaryButton>
          </Form>
        )
      else
        FormComponent = (
          <Form>
            <TextCurp value={curp} onChange={setCurp} />
            <PrimaryButton
              disabled={curp.length !== 18 || loading}
              onClick={loadCurp}
            >
              {' '}
              Quiero vacunarme{' '}
            </PrimaryButton>
          </Form>
        )
    }
    if (!serviceAvailable) {
      FormComponent = (
        <Form>
          <ErrorText color={'#676D72'}>{error}</ErrorText>
        </Form>
      )
    }
  }

  return (
    <Container>
      {!error && !loadingService && (
        <Title color={'#676D72'}>¡Comienza ahora!</Title>
      )}
      {FormComponent}
      {!error && !loadingService && (
        <BottomText>
          Si no conoces tu CURP{' '}
          <Link
            href='https://www.gob.mx/curp/'
            target='_blank'
            rel='noopner noreferrer'
          >
            consulta aquí
          </Link>
        </BottomText>
      )}
      {error && !loadingService && serviceAvailable && (
        <PrimaryButton
          onClick={() => {
            setError('')
          }}
        >
          Regresar
        </PrimaryButton>
      )}
    </Container>
  )
}

CurpForm.propTypes = {}

export default CurpForm
