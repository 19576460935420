import { css } from 'styled-components';

const styles = {
    container: () => css`
        background-color: #00326C;
        display: flex;
        align-items:center;
        justify-content:center;
        font-size: 1.5em;
        width: 100%;
        height: 308px;
        overflow: hidden;
    `,
    icon: () => css`
        overflow:hidden;
        position: absolute;
        pointer-events: none;
        right: 0px;
        top: -70px;
        transform: translateX(50%);
    `,
    texto: () => css`
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `,
};

export default styles;