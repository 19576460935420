import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './TextCurp.styles';

import {ReactComponent as CurpIcon} from '../../icons/curp.svg';

const Container = styled.div`${Styles.container}`;
const Icon = styled(CurpIcon)`${Styles.icon}`;
const TextCurpStyled = styled.input.attrs({
    type: 'input',
})`${Styles.textbox}`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const TextCurp = ({onChange, value}) => {
    const cleanCurp = (curp)=>{
        let cleanedCurp = curp.replace(/[^0-9a-z]/gi, '');
        return cleanedCurp.substring(0, 18).toUpperCase();
    }
    const onCurpChange = (event)=>{
        onChange(cleanCurp(event.target.value));
    };
    return (
        <Container>
            <Icon />
            <TextCurpStyled placeholder={"Introduce tu CURP"} onChange={onCurpChange} value={value} />
        </Container>
    );
};

TextCurp.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default TextCurp;
