import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Styles from './ConfirmInfo.styles'

import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'

import AddressBook from '../../icons/address-book-solid.png'
import { Info } from '../../components/InfoData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

const Container = styled.div`
  ${Styles.container}
`
const SubContainer = styled.div`
  ${Styles.subcontainer}
`
const Form = styled.div`
  ${Styles.form}
`
const Title = styled.h2`
  ${Styles.title}
`

const SubTitle = styled.h2`
  ${Styles.subtitle}
`
const Text = styled.span`
  ${Styles.text}
`
const Icon = styled.img`
  ${Styles.icon}
`
const ActionButtons = styled.div`
  margin-top: 3em;
  display: flex;
  > * {
    height: 2.5em;
    margin-right: 1.5em;
  }
`

const ConfirmInfo = ({
  curp,
  nombre,
  primerApellido,
  segundoApellido,
  fechaNacimiento,
  edad,
  sexo,
  entidadDeNac,
  entidadFederativa,

  telefono1,
  telefono2,
  email,
  calle,
  numExt,
  numInt,
  cp,
  municipio,
  colonia,
  diabetes,
  hipertension,
  otra,
  num_dosis
}) => {
  //   const [modulo, setModulo] = useState('')
  //   const [assistance, setAssistance] = useState(false)
  const [modulos, setModulos] = useState([])
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [user, setUser] = useState({})

  useEffect(() => {
    let cpAux = window.localStorage.getItem('cp')
    let municipioAux = window.localStorage.getItem('municipio')
    setUser({
      curp,
      nombre,
      primerApellido,
      segundoApellido,
      fechaNacimiento,
      edad,
      sexo,
      entidadDeNac,
      entidadFederativa,

      telefono1,
      telefono2,
      email,
      calle,
      numExt,
      numInt,
      cp: cpAux,
      municipio: municipioAux,
      colonia,
      diabetes,
      hipertension,
      otra,
      num_dosis
    })
    // setModulos([])
    // fetch(`${process.env.REACT_APP_API_URL}/registro/getModulosByCp?cp=${cp}`)
    //   .then(res => res.json())
    //   .then(data => {
    //     if (data.error) {
    //       const alertMsg =
    //         data.error_descripcion ||
    //         'Ocurrió un error cargando la lista de modulos. Por favor intenta de nuevo más tarde.'
    //       alert(alertMsg)
    //       window.location.href = '/'
    //       return
    //     }
    //     setModulos(
    //       data.map(modulo => ({
    //         value: modulo.id,
    //         caption: modulo.nombre
    //       }))
    //     )
    //     // if (data.length > 0) {
    //     //   setModulo(data[0].id)
    //     // }
    //   })
    //   .catch(err => {
    //     // alert(
    //     //   'Ocurrió un error cargando la lista de modulos. Por favor intenta de nuevo más tarde.'
    //     // )
    //     // window.location.href = '/'
    //   })
  }, [
    calle,
    colonia,
    cp,
    curp,
    diabetes,
    edad,
    email,
    entidadDeNac,
    entidadFederativa,
    fechaNacimiento,
    hipertension,
    municipio,
    nombre,
    numExt,
    numInt,
    num_dosis,
    otra,
    primerApellido,
    segundoApellido,
    sexo,
    telefono1,
    telefono2
  ])

  const registerCita = () => {
    setLoadingRegister(true)
    // fetch(`${process.env.REACT_APP_API_URL}/registro`, {
    fetch(`${process.env.REACT_APP_API_URL}/registro/agendarCita`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setLoadingRegister(false)
        if (data.error) {
          alert(data.error_descripcion)
          return
        }
        setLoadingRegister(false)
        window.localStorage.setItem('cita', JSON.stringify(data.cita))
        window.location.href = '/appointment'
      })
      .catch(e => {
        setLoadingRegister(false)
        alert(e)
      })
    // window.grecaptcha.ready(() => {
    //   window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
    //     fetch(`${process.env.REACT_APP_API_URL}/registro`, {
    //       method: 'POST',
    //       cache: 'no-cache',
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       referrerPolicy: 'no-referrer',
    //       body: JSON.stringify({
    //         curp: curp,
    //         cp: cp,
    //         // moduloVacunacion: modulo,
    //         // necesita_asistencia: assistance,
    //         num_dosis,
    //         token
    //       })
    //     })
    //       .then(res => res.json())
    //       .then(data => {
    //         if (data.error) {
    //           const alertMsg =
    //             data.error_descripcion ||
    //             'Ocurrió un error registrando tu cita. Por favor intenta de nuevo más tarde.'
    //           alert(alertMsg)
    //           setLoadingRegister(false)
    //           return
    //         }
    //         window.localStorage.setItem(
    //           'cita',
    //           JSON.stringify({
    //             curp: data.curp,
    //             nombre: `${data.nombre} ${data.primer_apellido} ${data.segundo_apellido}`,
    //             fecha_cita: `${data.fecha_cita_string.day_text}, ${data.fecha_cita_string.day} de ${data.fecha_cita_string.month}, a las ${data.fecha_cita_string.hour}`,
    //             modulo_nombre: data.modulo_vacunacion.nombre,
    //             modulo_direccion: data.modulo_vacunacion.direccion,
    //             uuid: data.uuid,
    //             numDosis: data.num_dosis
    //           })
    //         )
    //         setLoadingRegister(false)
    //         window.location.href = '/success'
    //       })
    //       .catch(err => {
    //         alert(
    //           'Ocurrió un error registrando tu cita. Por favor intenta de nuevo más tarde.'
    //         )
    //         setLoadingRegister(false)
    //         return
    //       })
    //   })
    // })
  }

  let FormComponent = <></>

  if (loadingRegister)
    FormComponent = (
      <PrimaryButton className='wait' disabled={true}>
        <FontAwesomeIcon icon={faCircleNotch} spin color='white' />
        &nbsp;
      </PrimaryButton>
    )
  else
    FormComponent = (
      <PrimaryButton
        // disabled={loadingRegister || modulo === '' || modulos.length === 0}
        disabled={loadingRegister}
        onClick={registerCita}
      >
        Guardar
      </PrimaryButton>
    )

  return (
    // (modulos.length > 0 && (
    <Container>
      <Icon src={AddressBook} alt='Icono libreta de direcciones' />
      <SubContainer>
        <Title>Confirmación de información</Title>
        <Text>
          Por último es necesario verifiques que toda la información que se ha
          registrado sea correcta.
        </Text>
        <Form>
          <Info caption='CURP' value={user.curp} />
          <Info caption='Nombre' value={user.nombre} />
          <Info caption='Primer apellido' value={user.primerApellido} />
          <Info caption='Segundo apellido' value={user.segundoApellido} />
          <Info
            caption='Fecha de nacimiento'
            value={fechaNacimiento.substring(0, 10)}
          />
          <Info caption='Edad' value={edad.toString()} />
          <Info caption='Entidad de nacimiento' value={user.entidadDeNac} />
          <Info
            caption='Sexo'
            value={user.sexo === 'M' ? 'Masculino' : 'Femenino'}
          />
        </Form>
        <Form>
          <SubTitle>Ubicación</SubTitle>
          <Info caption='Calle' value={user.calle} />
          <Info caption='Numero exterior' value={user.numExt} />
          <Info
            caption='Numero interior'
            value={user.numInt ? user.numInt : '-'}
          />
          <Info caption='Entidad federativa' value={user.entidadFederativa} />
          <Info caption='Código Postal' value={user.cp} />
          <Info caption='Municipio' value={user.municipio} />
          <Info caption='Colonia' value={user.colonia ? user.colonia : '-'} />
        </Form>
        <Form>
          <SubTitle>Contacto</SubTitle>
          <Info caption='Teléfono de contacto 1' value={telefono1} />
          <Info caption='Teléfono de contacto 2' value={telefono2} />
          <Info caption='Correo electrónico' value={email} />
        </Form>
        <Form>
          <SubTitle>Padecimientos</SubTitle>
          <Info caption='Diabetes' value={diabetes == 1 ? 'Si' : 'No'} />
          <Info
            caption='Hipertensión'
            value={hipertension == 1 ? 'Si' : 'No'}
          />
          <Info caption='Otra' value={otra ? otra : '-'} />
        </Form>
        <Form>
          <SubTitle>Dosis</SubTitle>
          <b style={{ color: 'black' }}>{num_dosis == 1 ? '1era' : '2da'}</b>
        </Form>
        <ActionButtons>
          <SecondaryButton
            disabled={false}
            onClick={() => {
              window.location.href = '/register'
            }}
          >
            Regresar
          </SecondaryButton>
          {FormComponent}
        </ActionButtons>
      </SubContainer>
    </Container>
    // )) || <></>
  )
}

export default ConfirmInfo
