import { css } from 'styled-components';

const styles = {
    button: ({backgroundColor, hoverColor}) => css`
        height: 2em;
        position: relative;
        font-weight: bold;
        color: white;
        background-color: ${backgroundColor};
        display: flex;
        font-size: 1em;
        font-family: 'Nutmeg', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        border-radius: 0.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        outline: none;
        border: none;
        padding: 1em;
        cursor: pointer;
        :hover:enabled{
            background-color: ${hoverColor};
            transition: backgroundColor 0.3s ease-in-out;
        }
        :disabled{
            opacity: 0.5;
            cursor:not-allowed;
        }
        .wait{
            cursor: wait;
        }
    `,
};

export default styles;