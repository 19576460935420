import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Styles from './RegisterForm.styles'

import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'

import {
  SelectInput,
  TextInput,
  RadioInput,
  JaliscoRadioInput
} from '../../components/FormElement'
import SenalAccesibilidad from '../../icons/senal-de-accesibilidad.svg'
import { JaliscoCheckboxInput } from '../../components/FormElement'

import AddressBook from '../../icons/address-book-solid.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

const Container = styled.div`
  ${Styles.container}
`
const SubContainer = styled.div`
  ${Styles.subcontainer}
`
const Form = styled.div`
  ${Styles.form}
`
const Title = styled.h2`
  ${Styles.title}
`
const SubTitle = styled.h2`
  ${Styles.subtitle}
`
const Text = styled.span`
  ${Styles.text}
`
const Icon = styled.img`
  ${Styles.icon}
`
const ActionButtons = styled.div`
  margin-top: 3em;
  display: flex;
  > * {
    height: 2.5em;
    margin-right: 1.5em;
  }
`

const RegisterForm = ({
  curp,
  nombre,
  primerApellido,
  segundoApellido,
  fechaNacimiento,
  edad,
  sexo,
  estadoNacNombre
}) => {
  const [municipio, setMunicipio] = useState('')
  const [numDosis, setNumDosis] = useState(0)
  const [colonias, setColonias] = useState([])
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [hasOtra, setHasOtra] = useState(false)

  const selectDosage = evt => {
    setNumDosis(evt.target.value)
  }

  const numDosisOptions = [
    {
      value: 1,
      caption: '1ra dosis'
    },
    {
      value: 2,
      caption: '2da dosis'
    }
  ]

  const padecimientosOptions = [
    {
      value: 1,
      caption: 'Si'
    },
    {
      value: 0,
      caption: 'No'
    }
  ]

  useEffect(() => {
    setLoading(false)
    let userRegister = window.localStorage.getItem('userRegister')
    if (userRegister) {
      setUser(JSON.parse(userRegister))
      return
    }
    setUser({
      curp,
      nombre,
      primerApellido,
      segundoApellido,
      fechaNacimiento,
      edad,
      sexo,
      entidadDeNac: estadoNacNombre,
      entidadFederativa: 'Jalisco',

      telefono1: '',
      telefono2: '',
      email: '',
      calle: '',
      numExt: '',
      numInt: '',
      cp: '',
      municipio: '',
      colonia: '',
      diabetes: '',
      hipertension: '',
      otra: '',
      num_dosis: ''
    })
  }, [
    curp,
    edad,
    estadoNacNombre,
    fechaNacimiento,
    nombre,
    primerApellido,
    segundoApellido,
    sexo
  ])

  const getColonias = evt => {
    const cpAux = evt.target.value
    if (cpAux.length !== 5) {
      setLoading(true)
      setMunicipio('')
      setColonias([
        {
          value: 0,
          caption: 'Escribe un código postal válido...'
        }
      ])
      return
    }
    window.localStorage.setItem('cp', cpAux)
    setLoading(true)

    setColonias([
      {
        value: 0,
        caption: 'Cargando...'
      }
    ])
    fetch(
      `${process.env.REACT_APP_API_URL}/registro/getColoniasByCp?cp=${cpAux}`
    )
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          const alertMsg =
            data.error_descripcion ||
            'Ocurrió un error cargando la lista de colonias. Por favor intenta de nuevo más tarde.'
          alert(alertMsg)
          return
        }
        setColonias(
          data.colonias.map(colonia => ({
            value: colonia.asentamiento,
            caption: colonia.asentamiento
          }))
        )
        setMunicipio(data.municipio)
        window.localStorage.setItem('municipio', data.municipio)
        setUser({
          ...user,
          municipio: data.municipio,
          cp: cpAux,
          colonia: colonias[0].caption
        })
        if (data.colonias.length > 0)
          setUser({ ...user, colonia: data.colonias[0].asentamiento })
        setLoading(false)
      })
      .catch(err => {
        alert(
          'Ocurrió un error cargando la lista de colonias. Por favor intenta de nuevo más tarde.'
        )
        setLoading(false)
      })
  }

  const registerCita = () => {
    window.localStorage.setItem('user', JSON.stringify(user))
    window.localStorage.setItem('userRegister', JSON.stringify(user))
    window.localStorage.setItem('curp', JSON.stringify(curp))
    window.location.href = '/confirm'
    console.log(user)
    return
    setLoadingRegister(true)
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        fetch(`${process.env.REACT_APP_API_URL}/registro`, {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            curp: curp,
            // cp: cp,
            num_dosis: numDosis,
            token
          })
        })
          .then(res => res.json())
          .then(data => {
            if (data.error) {
              const alertMsg =
                data.error_descripcion ||
                'Ocurrió un error registrando tu cita. Por favor intenta de nuevo más tarde.'
              alert(alertMsg)
              setLoadingRegister(false)
              return
            }
            window.localStorage.setItem(
              'cita',
              JSON.stringify({
                curp: data.curp,
                nombre: `${data.nombre} ${data.primer_apellido} ${data.segundo_apellido}`,
                fecha_cita: `${data.fecha_cita_string.day_text}, ${data.fecha_cita_string.day} de ${data.fecha_cita_string.month}, a las ${data.fecha_cita_string.hour}`,
                modulo_nombre: data.modulo_vacunacion.nombre,
                modulo_direccion: data.modulo_vacunacion.direccion,
                uuid: data.uuid,
                numDosis: data.num_dosis
              })
            )
            setLoadingRegister(false)
            window.location.href = `${process.env.REACT_APP_URL}` + '/success'
          })
          .catch(err => {
            alert(
              'Ocurrió un error registrando tu cita. Por favor intenta de nuevo más tarde.'
            )
            setLoadingRegister(false)
            return
          })
      })
    })
  }

  return (
    <Container>
      <Icon src={AddressBook} alt='Icono libreta de direcciones' />
      <SubContainer>
        <Title>Registro para Vacunación</Title>
        <Text>
          Es importante verifiques que la información que se presenta a
          continuación, corresponde a la CURP que estas solicitando y que
          coincide con los datos proporcionados
        </Text>
        <Form>
          <TextInput disabled caption='CURP' value={curp} />
          <TextInput disabled caption='Nombre' value={nombre} />
          <TextInput
            disabled
            caption='Primer apellido'
            value={primerApellido}
          />
          <TextInput
            disabled
            caption='Segundo apellido'
            value={segundoApellido}
          />
          <TextInput
            disabled
            caption='Fecha de nacimiento'
            value={fechaNacimiento.substring(0, 10)}
          />
          <TextInput disabled caption='Edad' value={edad.toString()} />
          <TextInput
            disabled
            caption='Entidad de nacimiento'
            value={user.entidadDeNac}
          />
          <TextInput
            disabled
            caption='Sexo'
            value={sexo === 'M' ? 'Masculino' : 'Femenino'}
          />
        </Form>
        <Form>
          <SubTitle>Contacto</SubTitle>
          <TextInput
            caption='Teléfono de contacto 1'
            onChange={evt => {
              setUser({ ...user, telefono1: evt.target.value })
            }}
            value={user.telefono1}
          />
          <TextInput
            caption='Teléfono de contacto 2'
            onChange={evt => {
              setUser({ ...user, telefono2: evt.target.value })
            }}
            value={user.telefono2}
          />
          <TextInput
            caption='Correo electrónico'
            onChange={evt => {
              setUser({ ...user, email: evt.target.value })
            }}
            value={user.email}
          />
        </Form>
        <Form>
          <SubTitle>Ubicación</SubTitle>
          <TextInput
            caption='Calle'
            onChange={evt => {
              setUser({ ...user, calle: evt.target.value })
            }}
            value={user.calle}
          />
          <TextInput
            caption='Numero exterior'
            onChange={evt => {
              setUser({ ...user, numExt: evt.target.value })
            }}
            value={user.numExt}
          />
          <TextInput
            caption='Numero interior'
            optional
            onChange={evt => {
              setUser({ ...user, numInt: evt.target.value })
            }}
            value={user.numInt}
          />
          <TextInput disabled caption='Entidad federativa' value={'Jalisco'} />
          <TextInput onChange={getColonias} caption='Código Postal' />
          <TextInput disabled caption='Municipio' value={municipio} />
          <SelectInput
            disabled={loading}
            caption='Colonia'
            options={colonias}
            optional
            onChange={evt => {
              setUser({ ...user, colonia: evt.target.value })
            }}
            selected={user.colonia}
          />
          {loading && (
            <FontAwesomeIcon icon={faCircleNotch} spin color='white' />
          )}
        </Form>
        <Form>
          <SubTitle>Padecimientos</SubTitle>
          <JaliscoRadioInput
            selected={user.diabetes}
            onChange={evt => {
              setUser({ ...user, diabetes: evt.target.value })
            }}
            options={padecimientosOptions}
            groupName='diabetes'
            style={{ marginTop: 0 }}
            caption={'Diabetes'}
          />
          <JaliscoRadioInput
            selected={user.hipertension}
            onChange={evt => {
              setUser({ ...user, hipertension: evt.target.value })
            }}
            options={padecimientosOptions}
            groupName='hipertension'
            style={{ marginTop: 0 }}
            caption={'Hipertensión'}
          />
          <JaliscoCheckboxInput
            checked={hasOtra}
            label={'Otra'}
            onChange={() => {
              let hasOtraAux = !hasOtra
              if (!hasOtraAux) setUser({ ...user, otra: '' })
              setHasOtra(hasOtraAux)
            }}
            image={false}
          />
          {hasOtra && (
            <TextInput
              caption='Describa aquí'
              onChange={evt => {
                setUser({ ...user, otra: evt.target.value })
              }}
              value={user.otra}
            />
          )}
        </Form>
        <Form>
          <SubTitle>Vacuna</SubTitle>
          <RadioInput
            onChange={evt => {
              setUser({ ...user, num_dosis: evt.target.value })
            }}
            options={numDosisOptions}
            selected={numDosis}
            groupName='numDosis'
            style={{ marginTop: 0 }}
          />
        </Form>
        <ActionButtons>
          <SecondaryButton
            disabled={false}
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_URL}`
            }}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton disabled={loadingRegister} onClick={registerCita}>
            Guardar
          </PrimaryButton>
        </ActionButtons>
      </SubContainer>
    </Container>
  )
}

export default RegisterForm
