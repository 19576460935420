import { css } from 'styled-components';

const styles = {
    container: () => css`
        padding-left: 1em;
        width:90%;
        height: 5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-bottom: 1px #C6CACE solid;
        opacity: 1;
    `,
    icon: () => css`
        width: 1.25em;
        height: 1.25em;
        margin-right: 1em;
        .a{fill:#37aefd;stroke:#37aefd;}
    `
};

export default styles;