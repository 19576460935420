import React from 'react'
import MomentoFrente from '../layout/MomentoFrente/'
import SponsorsLogos from '../layout/SponsorsLogos/'
import CuidarseLogos from '../layout/CuidarseLogos/'
import CurpHome from '../layout/CurpHome'
import HomeHeader from '../layout/HomeHeader'
import Footer from '../layout/Footer'

function Home () {
  window.localStorage.removeItem('userRegister')
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('municipio')
  window.localStorage.removeItem('cp')
  window.localStorage.removeItem('curp')
  return (
    <div className='App-header'>
      <HomeHeader />
      <MomentoFrente />
      <CurpHome />
      <CuidarseLogos color='#FFFFFF' />
      <SponsorsLogos color='#FAFAFA' marginSides='2em' />
      <Footer color={'#4B5056'} />
    </div>
  )
}

export default Home
