import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './CurpHome.styles';

import CurpForm from '../CurpForm';
import JaliscoHacemos from '../JaliscoHacemos';
import AvisoLink from '../../components/AvisoLink';

const Container = styled.div`${Styles.container}`;
const Hacemos = styled.div`
    width: 100%;
    position: absolute;
    top: 250px;
    z-index: 0;
`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color}) => {
    return (
        <Container>
            <CurpForm />
            <AvisoLink color={'#ffffff'}/>
            <Hacemos>
                <JaliscoHacemos />
            </Hacemos>
        </Container>
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
