import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './JaliscoHacemos.styles';

import IconFlecha from '../../icons/trazo_flecha_derecha.png';
import IconJalisco from '../../icons/logo_jalisco_lo_hacemos.png';
import IconRadar from '../../icons/logo_radar.png';

const Container = styled.div`${Styles.container}`;
const Flecha = styled.img`${Styles.icon}`;
const Radar = styled.img`
    position: absolute;
    pointer-events: none;
    left: 30px;
    top: 70px;
`;
const Jalisco = styled.img`
    width: 100%;
    max-width: 250px;
    z-index: 10;
`;
const CenterContainer = styled.div`
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: space-around;
    position: absolute;
    height: 300px;
    top: 100px;
`;
/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color}) => {
    return (
        <Container>
            <Radar src={IconRadar} />
            <CenterContainer>
            </CenterContainer>
            <Flecha src={IconFlecha} />
        </Container>
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
