import React from 'react';
import PropTypes from 'prop-types';

import IconsRow from '../../components/IconsRow'

import LogoRadar from '../../icons/logo_red_vacunacion_radar_jalisco.png';
import LogoCuidarse from '../../icons/logo_cuidarse_es_prevenir.png';

const logos = [
    {
        src: LogoRadar,
        alt: 'Logo Salud',
        maxWidth: '180px'
    },
    {
        src: LogoCuidarse,
        alt: 'Logo Servicios de Salud Jalisco',
        maxWidth: '180px'
    }
];

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color}) => {
    return (
        <div style={{width: '50%', height:'200px', display: 'flex', alignItems:'center', justifyContent:'center'}} >
            <IconsRow color={color} icons={logos} />
        </div>
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
