import { css } from 'styled-components';

const styles = {
    container: () => css`
        display: flex;
        justify-content:center;
        position: relative;
        color: black;
        font-size: 20px;
        width: 100%;
        @media(max-width: 700px){
            font-size:0.8em;
        }
    `,
    icon: () => css`
        position: absolute;
        pointer-events: none;
        left: 0px;
        padding: 0.5em;
        height: 1em;
        width: 1em;
        @media (min-width: 768px) {
            padding: 1.2em;
        }
    `,
    textbox: () => css`
        width: 100%;
        padding-left:2em;
        height: 2em;
        text-align: left;
        font: normal normal normal 1em Nutmeg;
        letter-spacing: 2px;
        color: #99A0A3;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 8px #95949198;
        border: 0.5px solid #D0D0D0;
        opacity: 1;
        @media (min-width: 768px) {
            padding-left:3em;
            height: 3.2em;
        }
    `,
};

export default styles;