import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './AuthUser.styles';

import {ReactComponent as UserBlue} from '../../icons/user_blue.svg';

const Container = styled.div`${Styles.container}`;
const Icon = styled(UserBlue)`${Styles.icon}`;
const TextLine = styled.span`
    color: #676D72;
`;
const TextNormal = styled.span`
`;
const TextBold = styled.span`
    font-weight: bold;
`;
/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AuthUser = ({CURP}) => {
    return (
        <Container>
            <Icon />
            <TextLine><TextNormal>CURP: </TextNormal><TextBold>{CURP}</TextBold></TextLine>
        </Container>
    );
};

AuthUser.propTypes = {
    CURP: PropTypes.string.isRequired
};

export default AuthUser;
