import React, { useEffect, useState } from 'react'
import AuthHeader from '../layout/AuthHeader'
import AuthUser from '../layout/AuthUser'
import SponsorsLogos from '../layout/SponsorsLogos'
import Footer from '../layout/Footer'
import ConfirmInfo from '../layout/ConfirmInfo'
function Confirm () {
  const [user, setUser] = useState({})
  useEffect(() => {
    const userData = window.localStorage.getItem('userRegister')
    if (!userData) {
      alert('Debe ingresar su CURP primero')
      window.location.href = '/'
    }
    try {
      const localUser = JSON.parse(userData)
      setUser(localUser)
    } catch (ex) {
      window.localStorage.removeItem('user')
      window.location.href = '/'
    }
  }, [])
  return (
    (user.curp && (
      <div className='App-header' style={{ minHeight: '100vh' }}>
        <AuthHeader />
        <div
          style={{
            width: '100%',
            position: 'absolute',
            top: '110px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '80%'
            }}
          >
            <AuthUser CURP={user.curp} />
            <ConfirmInfo {...user} />
          </div>
          <SponsorsLogos color={'white'} />
          <Footer color={'#00326C'} />
        </div>
      </div>
    )) || <></>
  )
}

export default Confirm
