import { css } from 'styled-components';

const styles = {
    container: () => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 20;
        font-size: 1em;
        padding: 2em 0;
    `,
    icon: ({color}) => css`
        height: 1em;
        width: 1em;
        stroke: ${color || "#FFFFFF"};
        fill: ${color || "#FFFFFF"};
    `,
    link: ({color}) => css`
        text-decoration: none;
        text-align: left;
        font: normal normal normal 1em Nutmeg;
        font-weight: bold;
        color: ${color || '#FFFFFF'};
        margin-left: 0.5em;
    `,
};

export default styles;
