import React from 'react';
import styled from 'styled-components';
import Styles from './AvisoLink.styles';

import {ReactComponent as InfoIcon} from '../../icons/icon_info.svg';

import AvisoPDF from  '../../assets/pdf/AVISOS DE PRIVACIDAD.pdf';

const Container = styled.div`${Styles.container}`;
const Icon = styled(InfoIcon)`${Styles.icon}`;
const Link = styled.a`${Styles.link}`;



/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AvisoLink = ({color}) => {
    return (
        <Container>
            <Icon color={color}/>
	    <Link href={AvisoPDF} rel="noopener noreferrer" target="_blank" color={color}>Aviso de Privacidad</Link>
        </Container>
    );
};

AvisoLink.propTypes = {
};

export default AvisoLink;
