import { css } from 'styled-components';

const styles = {
    footer: ({color}) => css`
        background-color: ${color};
        width: 100%;
        height: 185px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        @media (max-width: 600px){
            flex-direction: column;
            height: 300px;
        }
    `,
    footerIcon: () => css`
        max-height: 110px;
    `
};

export default styles;