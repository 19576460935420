import React from 'react'
import styled from 'styled-components'
import Styles from '../FormElement/FormElement.styles'

const Container = styled.div`
  ${Styles.container}
`
const Caption = styled.div`
  ${Styles.caption}
`

const Info = ({ caption, value }) => {
  return (
    <Container>
      <Caption>{caption}</Caption>
      <b style={{ color: 'black' }}>{value}</b>
    </Container>
  )
}

const DataInfo = { Info }
export default DataInfo
export { Info }
