import React from 'react';
import PropTypes from 'prop-types';

import IconsRow from '../../components/IconsRow'

import LogoEducacion from '../../icons/logo_educacion.svg';
import LogoSalud from '../../icons/logo_salud.svg';
import LogoJalisco from '../../icons/logo_gobierno_jalisco.svg';

const logos = [
    {
        src: LogoSalud,
        alt: 'Logo Servicios de Salud Jalisco',
        maxWidth: '160px'
    },
    {
        src: LogoJalisco,
        alt: 'Logo Gobierno del estado de Jalisco',
        maxWidth: '75px'
    }
];

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AppointmentValidateLogos = ({color}) => {
    return (
        <IconsRow color={color} icons={logos} />
    );
};

AppointmentValidateLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default AppointmentValidateLogos;
