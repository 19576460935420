import { css } from 'styled-components';
import bgAPpointment from '../../icons/bg_appointment.png';

const styles = {
    container: () => css`
        width: 100%;
        background-image: url(${bgAPpointment});
        background-position: 0 -7vh;
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: 100vh;
    `,
    card: () => css`
        max-width: 550px;
        width: 100%;
        background-color: white;
        margin: 0 auto;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    `,
    card_heading: ({ color }) => css `
        background-color: ${color};
        padding: 15px;
        font-size: 24px;
        color: white;
        font-weight: bold;
    `,
    card_body: () => css `
        padding: 30px;
        font-size: 18px;
        color: white;
        font-weight: bold;
        text-align: center;

        .icon {
            margin: 0 auto;
            display: block;
            width: 100%;
        }

        .icon-status {
            margin: 0 auto;
            max-width: 180px;
        }

        .icon-logo-top {
            max-width: 160px;
            margin-bottom: 30px;
        }
    `,
    card_body_content: ({ color }) => css `
        color: #3B3A3A;
        width: 80%;
        margin: 0 auto;

        .citizen {
            margin-bottom: 30px;

            .folio {
                display: block;
                font-size: 24px;
                font-weight: bolder;
                margin-bottom: 30px;
            }
    
            .curp {
                display: block;
                color: #7B868C;
                font-size: 22px;
                font-weight: 300;
                margin-bottom: 5px;

                &-value {
                    display: block;
                    color: #3B3A3A;
                    font-weight: 600;
                }
            }
    
            .name {
                display: block;
            }
        }

        .appointment {
            .module {
                color: #7B868C;
                font-weight: 400;
                margin-bottom: 30px;
                display: block;

                &-name {
                    color: #3B3A3A;
                    display: block;
                    font-weight: 800;
                }

                &-municipality {
                    color: #37AEFD;
                    display: block;
                }
            }
        }

        .date {
            font-size: 16px;

            &-badge {
                background-color: ${color};
                color: white;
                font-weight: 600;
                display: inline-block;
                padding: 5px 25px;
                border-radius: 20px;
                margin-bottom: 15px;
            }

            &-msg {
                font-weight: 400;
                color: #7B868C;
                display: block;
            }

            &-hour {
                color: #37AEFD;
                display: block;
                margin-bottom:10px;
            }
        }

        .error {
            min-height: 17vh;
            display: flex;
            justify-content: center;
            align-items: center;

            &-text {
                font-size: 24px;
                font-weight: 800;
            }
        }
    `, 
    card_footer: () => css `
        width: 80%;
        margin: 0 auto;
        display: block;
        border-top: 1px solid #969A9A;
    `,
};

export default styles;