import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Styles from './AppointmentResult.styles';
import LogoExclamacion from '../../icons/signo-de-exclamacion.svg';
import SuccessLogo from '../../icons/success.svg';
import PropTypes from 'prop-types';
import PrimaryButton from '../../components/PrimaryButton';


const Container = styled.div`${Styles.container}`;
const ContainerInfoView = styled.div``;
const Icon = styled.img`${Styles.icon}`;
const ContainerInfo = styled.div`${Styles.containerInfo}`;
const Title = styled.h2`${Styles.title}`;
const TitleSuccess = styled.h2`${Styles.titleSuccess}`;
const Info = styled.p`${Styles.info}`;
const Instructions = styled.div`${Styles.instructions}`;
const TextLine = styled.div`${Styles.textLine}`;
const TextNormal = styled.span`${Styles.textLine}`;
const TextBold = styled.span`
    font-weight: bold;
`;
const Link = styled.a`
    font-weight: bold;
    color: #015DB1;
    cursor: pointer;
    text-decoration: underline;
`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AppointmentResult = ({ appointment, curp, validation }) => {

    useEffect(() => {

    }, []);

    const routing = (route, blank = 0) => {
        blank ? window.open(`${route}`, '_blank') : window.location.href = `${route}`;
    }

    const CURPNotFoundView = () => {
        return <>
            <Container className="icon">
                <Icon src={LogoExclamacion} alt="No encontrado" />
            </Container>
            <Container>
                <Title>La CURP que está buscando no cuenta con un registro.</Title>
                <br />
                <PrimaryButton onClick={() => routing(`${process.env.REACT_APP_URL}`)}>Regresar</PrimaryButton>
            </Container>
        </>;
    }

    const UdeGView = (appointment) => {
        return <>
            <Container className="icon">
                <Icon src={LogoExclamacion} alt="Alert" />
            </Container>
            <Container>
                <TitleSuccess>Atención</TitleSuccess>
                <TitleSuccess>Trabajador Universidad de Guadalajara</TitleSuccess>
                <br />
                
                <TextLine>
                    <TextNormal className="title">CURP: </TextNormal><TextBold>{appointment.curp}</TextBold>
                </TextLine>
                <TextLine>
                    <TextNormal className="title">Nombre: </TextNormal><TextBold>{appointment.nombre + ' ' + appointment.primer_apellido + ' ' + appointment.segundo_apellido} </TextBold>
                </TextLine>
                <br />
                <TextLine>
                    <TextBold>Si eres trabajador de la Universidad de Guadalajara de un plantel del Área Metropolitana, deberás registrarte en <a href="http://www.vacuna.udg.mx" rel="noopener noreferrer" target="_blank">www.vacuna.udg.mx</a> para agendar tu cita en el módulo UdeG, ubicado en la Plaza Bicentenario del Centro Cultural Universitario.</TextBold>
                </TextLine>
            </Container>
        </>;
    }

    const OutFase = (appointment) => {
        return <>
            <Container className="icon">
                <Icon src={LogoExclamacion} alt="Alert" />
            </Container>
            <Container>
                <TitleSuccess>Atención</TitleSuccess>
                <br />
                
                <TextLine>
                    <TextNormal className="title">CURP: </TextNormal><TextBold>{appointment.curp}</TextBold>
                </TextLine>
                <TextLine>
                    <TextNormal className="title">Nombre: </TextNormal><TextBold>{appointment.nombre + ' ' + appointment.primer_apellido + ' ' + appointment.segundo_apellido} </TextBold>
                </TextLine>
                <br />
                <TextLine>
                    <TextBold>En este  momento tu información está en proceso de programación,  te sugerimos  intentar más tarde.</TextBold>
                </TextLine>
            </Container>
        </>;
    }

    const successView = (appointment) => {
        return <>
            <Container className="icon">
                <Icon src={SuccessLogo} alt="Success" />
            </Container>
            <Container>
                <TitleSuccess>Felicidades</TitleSuccess>
                <TitleSuccess>Ya te encuentras registrado para vacunarte.</TitleSuccess>
                {appointment.validation && infoValidationView()}
                <br />
                
                <TextLine>
                    <TextNormal className="title">CURP: </TextNormal><TextBold>{appointment.curp}</TextBold>
                </TextLine>
                <TextLine>
                    <TextNormal className="title">Nombre: </TextNormal><TextBold>{appointment.nombre + ' ' + appointment.primer_apellido + ' ' + appointment.segundo_apellido} </TextBold>
                </TextLine>

                {!appointment.validation && infoSuccessView()}
            </Container>
        </>;
    }

    const infoValidationView = () => {
        return <>
            <TextLine >
                <TextBold>A partir del martes podrás consultar en este mismo sitio web, sede, día y hora en que deberás presentarte para tu vacunación.</TextBold>
            </TextLine>
        </>;
    }

    const infoSuccessView = () => {
        return <>
            <ContainerInfoView>
                <Instructions>
                <TextLine>
                    <TextBold>De acuerdo a tu perfil y la información de tu registro se te ha asignado tu invitación el día, {`${appointment.fecha_cita_string.day_text}, ${appointment.fecha_cita_string.day} de ${appointment.fecha_cita_string.month} a las ${appointment.fecha_cita_string.hour}`} en el Módulo: {appointment.modulo_vacunacion.nombre_modulo}.</TextBold>
                </TextLine>                </Instructions>
                <PrimaryButton onClick={() => routing(`${process.env.REACT_APP_DOWNLOAD_URL}/registro/${appointment.uuid}/getPdf`, 1)}>Descargar invitación</PrimaryButton>
                <br />
                <PrimaryButton onClick={() => routing(`${process.env.REACT_APP_DOWNLOAD_URL}/registro/${appointment.uuid}/getPdfExpediente`, 1)}>Descargar expediente de vacunación</PrimaryButton>
                <br />
                <PrimaryButton onClick={() => routing(`${process.env.REACT_APP_DOWNLOAD_URL}/registro/${appointment.uuid}/getPdfExpediente2`, 1)}>Descargar expediente de vacunación 2</PrimaryButton>
                <br />
                <Link onClick={() => routing(`https://vacunascovid.gob.mx/guivcvd-web/acceso`, 1)}>Valida tus datos en vacunascovid.gob.mx (REGISTRO)</Link>
            </ContainerInfoView>
        </>;
    }

    const SelectType = ({ type, appointment }) => {
        let compMount = <>Sin resultado</>;
        if (appointment.udg){
            return UdeGView(appointment);
        }
        if (appointment.fase && appointment.fase !== "1" && !appointment.uuid){
            return OutFase(appointment);
        }
        if (type)
            switch (type) {
                case 1:
                    compMount = successView(appointment);
                    break;
                case 2:
                    compMount = CURPNotFoundView();
                    break;
            }
        return compMount;
    }

    const getTypeData = (curp, appointment) => {
        return {
            curp,
            ...appointment,
            validation
        }
    };

    return (
        <Container>
            <ContainerInfo>
                <SelectType type={(appointment) ? 1 : 2} appointment={getTypeData(curp, appointment)} />
            </ContainerInfo>
        </Container >
    );
};

AppointmentResult.propTypes = {
    type: PropTypes.string.isRequired
};

export default AppointmentResult;
