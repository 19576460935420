import { css } from 'styled-components';

const styles = {
    container: () => css`
        margin-top: 2rem;
        color: #676D72;
        width: 80%;

        @media (max-width: 768px) {
            margin: 1rem 0;
        }
    `,
    containerCurp: () => css`
        display: flex;
        font-size: 22px;
        font-weight: 300;
        padding-bottom: 30px;
        border-bottom: 0.5px solid #BABABA;
        margin-bottom: 80px;

        .icon{
            margin-right: 30px;
            width: 60px;

            @media (max-width: 768px) {
                width: 30px;
                margin-right: 15px;

                img {
                    width: 100%;
                }
            }
        }

        .data{
            margin-left: 5px;
            font-weight: bold;
        }
    `,
    containerInfo: () => css`
        min-height: 50vh;
        display: flex;
        font-weight: 300;
        justify-content: center;
        align-items: flex-start;
        text-align: left;

        @media (max-width: 768px) {
            height: auto;
        }

        .icon{
            margin-right: 30px;
            width: 60px;

            @media (max-width: 768px) {
                width: 30px;
                margin-right: 15px;

                img {
                    width: 100%;
                }
            }
        }
    `,
    title: () => css`
        color: #00326C;
        font-size: 34px;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    `,
    titleSuccess: () => css`
        color: #015DB1;
        font-size: 34px;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    `,
    info: () => css`
        font-size: 18px;
    `,
    instructions: () => css`
        word-break: break-all;
        background-color:#fafafa;
        padding: 10px;
        margin: 30px 0;
        color:#4B5056;
        width: 55em;

        @media (max-width: 768px) {
            width: 100%;
            word-break: break-word;
        }
    `,
    textLine: () => css`
        color: #676D72;
        display: flex;
        font-size: 16px;

        .title{
            margin-right: 5px;
        }

        @media (max-width: 768px) {
            display: block;
        }  
    `,

};

export default styles;