import { css } from 'styled-components';

const styles = {
    container: () => css`
        position: relative;
        color: #676D72;
        display: flex;
        font-size: 2em;
        width: 100%;
        height: 200px;
        @media(max-width: 600px){
            font-size: 2em;
        }
    `,
    icon: () => css`
        position: absolute;
        pointer-events: none;
        left: 0px;
        width: 120px;
        transform: translateX(-50%);
        @media(max-width:600px){
            transform: translateX(-75%);
        }
    `,
    texto: () => css`
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 2em;
        top: 50%;
        left: 50%;
        z-index:10;
        transform: translate(-50%, -50%);
    `,
};

export default styles;