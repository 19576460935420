import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './ActionButton.styles';

const Button = styled.button`${Styles.button}`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const ActionButton = ({disabled, backgroundColor, hoverColor, onClick, children}) => {
    return (
        <Button disabled={disabled} backgroundColor={backgroundColor} hoverColor={hoverColor} onClick={onClick} >{children}</Button>
    );
};

ActionButton.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired
};

export default ActionButton;
