import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../ActionButton'
import {PrimaryColor, PrimaryHoverColor} from '../../layout/styles.default.js'

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const PrimaryButton = ({onClick, disabled, children}) => {
    return (
        <ActionButton disabled={disabled} backgroundColor={PrimaryColor} hoverColor={PrimaryHoverColor} onClick={onClick} >{children}</ActionButton>
    );
};

PrimaryButton.propTypes = {
};

export default PrimaryButton;
